<template>
  <div class="appointmentList-view">
    <div class="flex-a-b-c">
      <div class="d-flex">
        <div class="mr20">
          <el-select class="mr20" style="width: 240px;" v-model="formDetailType" clearable placeholder="请选择">
            <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
            </el-option>
          </el-select>
          <el-date-picker clearable
                  v-model="createTime"
                  type="daterange"  style="width: 390px"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <el-button type="primary" @click="searchPage">查询</el-button>
      </div>
      <div class="ml-auto d-flex align-cen" >
        <el-button type="primary" @click="exportData">导出</el-button>
      </div>
    </div>

    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="currentPage"
                 :loading="loading"
                 :total="total">
      <template v-slot:table>
        <el-table-column align="center" type="index" width="50" label="序号"> </el-table-column>
        <el-table-column align="center" style="-webkit-align-items: center;text-overflow:ellipsis;overflow: hidden;white-space: nowrap;-webkit-box-orient: vertical;"
                          prop="name" label="用户名" > </el-table-column>
        <el-table-column align="center" prop="commitTime" width="170" label="创建时间" > </el-table-column>
        <div v-for="(item ,index) in options" :key="item.id">
          <el-table-column align="center" v-if="item.id == ('SINGLE' + (index - 1))"  :label="item.name">
            <template slot-scope= "scope">
              <span > {{scope.row['single'+(index)] }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" v-else-if="item.id == ('MULTIPLE' + (index - 1)) "  prop="multiple" :label="item.name"></el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'DROP_DOWN'  + (index - 1) "  prop="dropDown" :label="item.name"></el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'TEXTAREA' + (index - 1) "  prop="textarea" :label="item.name"></el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'SCOURE' + (index - 1) "  prop="scoure" :label="item.name"></el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'IMAGE'+ (index - 1) "  prop="image" :label="item.name">
            <template slot-scope="scope">
              <el-image v-if="scope.row.image.length > 0"
                      style="width: 36px;height:36px"
                      :src="scope.row.image[0]"
                      :preview-src-list="scope.row.image">
              </el-image>
              <span v-if="scope.row.image.length > 0">x{{scope.row.image.length}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'GPS' + (index - 1)"  prop="gps" :label="item.name"></el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'USERINFO' + (index - 1) "  prop="userInfo" :label="item.name">
            <template slot-scope= "scope">
              <div  v-for="(info,index) in scope.row.userInfo" :key="index">
                <span>{{info.answer}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'DATEPICKER' + (index - 1) "  prop="datepicker" :label="item.name"></el-table-column>
        </div>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  queryUserCommitDetail,
  queryAdvancedFormById,

} from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils";
export default {
  name: "appointmentList",
  components: {
    commonTable,
  },
  data() {
    return {
      value: "",
      tableData: [],
      optionList:'', //全部分类
      loading:false,
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      options:[
        {id:'',name:"全部"}
      ],
      createTime:'',
      formDetailType:'',
      start:'',
      end:'',
      advancedFormId:''
    };
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  created(){
    //检查是否编辑
    let advancedFormId = this.$route.query.id;

    if (advancedFormId) {
      this.advancedFormId = advancedFormId
      this.getTableData();
      this.getDetail()
    }
  },
  watch:{
    createTime(val,old){
      this.start =  getDataTimeSec(this.createTime[0], 'yy-mm-dd') +" 00:00:00"
      this.end = getDataTimeSec(this.createTime[1], 'yy-mm-dd') +" 23:59:59"
    }
  },
  methods:{
    getDetail(){
      queryAdvancedFormById({
        advancedFormId:this.advancedFormId
      }).then(res => {
        this.form = res.data;
        this.form.detailRequests.forEach((item,index) =>{
          this.options.push( {id:item.formType+index,name:item.title});
        });
      });
    },
    //请求预约商品列表数据
    getTableData() {
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        advancedFormId:this.advancedFormId,
        startTime:this.start || '',
        endTime:this.end || '',
        formDetailType:this.formDetailType || ''
      };

      queryUserCommitDetail(data)
        .then((res) => {
          this.loading = false;
          if (res && res.data.pageInfo.list[0] != null) {

            this.tableData = res.data.pageInfo.list;
            this.total = res.data.pageInfo.total;
            this.tableData.forEach(item =>{
              if(item.datepicker && item.datepicker != ""){
                const datepicker = JSON.parse(item.datepicker);
                if( datepicker &&  datepicker[0]){
                  item.datepicker = datepicker[0].answer;
                }
              }
              if(item.userInfo && item.userInfo != ""){
                const userInfo = JSON.parse(item.userInfo);
                item.userInfo = userInfo;//.map(function(user){return user.answer}).join("\n");
              }

              if(item.image && item.image != ""){
                const image = JSON.parse(item.image);
                item.image = image[0].answer;
              }

              if(item.scoure && item.scoure != ""){
                const scoure = JSON.parse(item.scoure);
                item.scoure = scoure.map(function(sc){return parseInt(sc.answer) + 1}).join("星 ") + "星";
              }

              if(item.textarea && item.textarea != ""){
                const textarea = JSON.parse(item.textarea);
                item.textarea = textarea[0].answer;
              }else{
                item.textarea = "";
              }

              //const dropDown = JSON.parse(item.dropDown);
              if(item.single && item.single != ""){
                try{
                    const singTemp = item.single.split("=");
                    const single = JSON.parse(singTemp[0]);
                    const result = single.find(function(sc){ return sc.answer == true });
                    item.single1 = result ? result.formDetailtitle : '';

                    const single2 = JSON.parse(singTemp[1]);
                    const result2 = single2.find(function(sc){ return sc.answer == true });
                    item.single2 = result2 ? result2.formDetailtitle : '';
                }catch(error){
                    console.log(error)
                }
              }else{
                item.single = "";
              }

              if(item.multiple && item.multiple != ""){
                const multiple = JSON.parse(item.multiple);
                item.multiple = multiple.map(function(sc){return sc.formDetailtitle}).join("/");
              }else{
                item.multiple = "";
              }
            })
          }else{
            this.tableData = [];
            this.totalCount = 0;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
          console.log(err);
        });
    },
    //筛选查询符合条件数据
    queryAppointmentPage() {
      this.currentPage = 1;
      this.pageNum = 1;
      //this.loading = true;
      this.getTableData();
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
    searchPage(){
      this.getTableData();
    },
    exportData(){

    }
  }
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>
